import { Injectable } from '@angular/core';
import {
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Util } from './util';
import { Component, Input } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Location } from '@angular/common';

@Injectable()
export class HttpServer {
  private apiUrl = 'https://api-uat.savemart.com:8443/oauth/token';


  constructor(
    private util: Util,
    private loc: Location
  ) {
  }

  SendData(
    model: any,
    url: any,
    mode: string,
    isBannerNeed = true,
    isDirectUrl = false,
    token: any =null ,
    bannerId='1',

  ) {

    if (isDirectUrl === true) {
      url = url;
    }
    else {
      const IsCSPUrl = environment.CSPUrl.find((x: any) => x.toString().toLowerCase() === url.toString().toLowerCase())
      if (this.util.isProd()) {
        url = (this.util.IsNotNull(IsCSPUrl) ?
          environment.prodShoreCSPAPI : environment.prodShoreAPI) + '/' + url;
      }
      else if (this.util.isUAT()) {
        url = (this.util.IsNotNull(IsCSPUrl) ?
          environment.uatShoreCSPAPI : environment.uatShoreAPI) + '/' + url;
      }
      else if (this.util.isDev()) {
        url = (this.util.IsNotNull(IsCSPUrl) ?
          environment.devShoreCSPAPI : environment.devShoreAPI) + '/' + url;
      }
      else {
        url = (this.util.IsNotNull(IsCSPUrl) ?
          environment.devShoreCSPAPI : environment.devShoreAPI) + '/' + url;

        // url = (this.util.IsNotNull(IsCSPUrl) ?
        // environment.offShoreCSPAPI : environment.offShoreAPI) + '/' + url;

        //url = environment.devShoreAPI + '/' + url;
        //url = environment.OffShoreAPI + '/' + url;
      }
    }

    let headers: any;
    headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: '',
      Banner: ''
    };

    if (url.includes("7py8fahnf3") || url.includes("llz1npmgm7")) {
      if (bannerId != null && bannerId.toString() == "2") {
        headers.Banner = 'luckysupermarkets';
      }
      else if (bannerId != null && bannerId.toString() == "3") {
        headers.Banner = 'foodmaxx';
      }
      else {
        headers.Banner = 'savemart';
      }
    }

    if (!this.util.IsNotEmpty(headers.Banner)) {
      delete headers.Banner
    }
    if (!this.util.IsNotEmpty(token)) {
      token = this.util.getToken();
    }

    const excludeBearerUrls = [environment.searchPhone, environment.searchEmail];
    const excludeBearer = excludeBearerUrls.some((excludeUrl) => url.includes(excludeUrl));
    
    if (this.util.IsNotEmpty(token)) {
      headers.Authorization = excludeBearer ? token : 'Bearer ' + token;
    }
    
  if (!this.util.IsNotEmpty(headers.Authorization)) {
      delete headers.Authorization;
    }
    // delete headers.Authorization;

    return fetch(url, {
      method: mode ? mode.toUpperCase() : 'GET',
      headers,
      body: model ? JSON.stringify(model) : null,
    }).
      then((Response) => Response.clone().json()).
      catch(function () {
        console.log("error");
      });
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // Server-side errors
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    window.alert(errorMessage);
    return throwError(errorMessage);
  }



  getAuthToken(){

    this.apiUrl = 'https://api-uat.savemart.com:8443/oauth/token';
    let username = 'oauthclient-support-portal-app';
    let password = 'PnJteVYYCJKM0dH_mIG.IgPRFV6.YcyQrkeZCL54jOd7ogSs~bXoRSDmSBbUX~J-';
    if (this.util.isProd()) {
      this.apiUrl = 'https://api.savemart.com:8443/oauth/token';
      username = 'oauthclient-support-portal-app';
      password = 'Nh~e2HwXU7DpGlLAAOVz2kvT3npKYL0ox7s.BvS-9PnkfzEVSACke_f1f6n1GtAq';
      }

  
    const credentials = btoa(`${username}:${password}`);
  
    const headers = {
      'Authorization': `Basic ${credentials}`, 
      'Content-Type': 'application/x-www-form-urlencoded'
    };
  
    return fetch(this.apiUrl, { headers })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch token: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => data.access_token) // Use `data.bearer` to get the token
      .catch(error => {
        console.error('Error fetching auth token:', error);
        throw error;
      });
  }




  
    


  AuthToken(): Promise<string> {
    this.apiUrl = 'https://us-west2-customer-service-portal-dev.cloudfunctions.net/GetAuthToken';
    this.util.setlocation(location);
    if (this.util.isProd()) {
      this.apiUrl = 'https://us-west2-customer-service-portal-prod.cloudfunctions.net/GetAuthToken';
    }
    let apiKey = 'IXZETpSA54k56HhrBV-LE4EobXqFwVy4Hb9urcpLFzY=:gAAAAABnyW64hWHK3JU4VqwdHa0Vx0QNLpyM6YSdtrb1cXbVXK3Hd0hhWspWC1xyOXGmR-Ve2Vvj_ZDIKCWMtEqPc1LONZLuTWeUDZMj9Ro9XFSOGNuVg5lyU2tA4H_eDPbqnxxJ2BTgX_0NuSsPPH3q21MTlEGC-UeYdSquHgoDY3wPX7TGHNQ=';
  
    const headers = {
      'Authorization': `Bearer ${apiKey}`
    };
  
    return fetch(this.apiUrl, { headers })
      .then(response => {
        if (!response.ok) {
          throw new Error(`Failed to fetch token: ${response.statusText}`);
        }
        return response.json();
      })
      .then(data => data.bearer) // Use `data.bearer` to get the token
      .catch(error => {
        console.error('Error fetching auth token:', error);
        throw error;
      });
  }

  
}
