// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

export const environment = {
  production: false,
  API: '',
  offShoreAPI:'https://us-central1-customer-service-portal-325207.cloudfunctions.net/',
  devShoreAPI:'https://us-west2-customer-service-portal-dev.cloudfunctions.net/',
  uatShoreAPI:'https://us-west2-customer-service-portal-dev.cloudfunctions.net/',
  prodShoreAPI:'https://us-west2-customer-service-portal-prod.cloudfunctions.net/',
  redirectURLSSO: 'https://sso.connect.pingidentity.com/sso/sp/initsso?saasid=489c5d8f-6cc5-4ce9-9848-ce184392e50a&idpid=new-item-billing.wl.r.appspot.com',

  offShoreCSPAPI: 'https://7py8fahnf3.execute-api.us-west-1.amazonaws.com/v1/',
  devShoreCSPAPI: 'https://7py8fahnf3.execute-api.us-west-1.amazonaws.com/v1',
  uatShoreCSPAPI: 'https://7py8fahnf3.execute-api.us-west-1.amazonaws.com/v1',
  // offShoreCSPAPI:'https://ibvq8rb2i5.execute-api.us-west-1.amazonaws.com/v2_Cors',
  // devShoreCSPAPI : 'https://ibvq8rb2i5.execute-api.us-west-1.amazonaws.com/v2_Cors',
  // uatShoreCSPAPI : 'https://ibvq8rb2i5.execute-api.us-west-1.amazonaws.com/v2_Cors',
  prodShoreCSPAPI: 'https://llz1npmgm7.execute-api.us-west-1.amazonaws.com/v1/',

  createServiceRequest: 'createServiceRequest',
  verifyLogin: 'verifyLogin',
  getMasterValues: 'getMasterValues',
  createCustomer: 'createCustomerInfo',

  searchEmail: 'profile/search/email',
  searchPhone: 'profile/search/phone',
  updateAccount: 'profile/update/account',
  emailpreference: 'profile/update/emailpreference',
  homestore: 'profile/update/homestore',
  readEmail: "profile/guid/account/email/read",
  readPhone: "profile/guid/account/phone/read",
  updatePhone: "profile/update/phone",
  updateEmail: "profile/update/email",
  deleteAccount: "ccpa/account/delete",
  mergeAccount:"merge/customer-account",
  mergeAccountfinal:"merge/customer-account/final-merge",

  CSPUrl: [
    'profile/search/email', 'profile/search/phone', 'profile/update/account', 'profile/update/emailpreference',
    'profile/update/homestore', 'profile/guid/account/email/read', 'profile/guid/account/phone/read',
    'profile/update/phone', 'profile/update/email', 'ccpa/account/delete','merge/customer-account','merge/customer-account/final-merge'
  ]
};
